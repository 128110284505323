import React from "react"
import { Flex, Box } from "rebass"
import Button from "@components/button"
import { download } from "@constants/urls.js"
import { StaticImage } from "gatsby-plugin-image"

const CTA = ({ title, subtitle }) => (
  <Flex
    mt={30}
    pt={16}
    pb={16}
    flexWrap="wrap"
    display={["none", "none", "block"]}
  >
    <Flex flexDirection="row" alignContent={"center"}>
      <Box width={[1 / 2]} mb={[10, 10, 0]}>
        <h3
          style={{
            marginBottom: "5px",
            lineHeight: "1.5",
            letterSpacing: "-0.3px",
          }}
        >
          {title}
        </h3>
        <h3
          style={{ color: "#aaa", marginBottom: 10, letterSpacing: "-0.3px" }}
        >
          {subtitle}
        </h3>

        <Button
          primary
          href={download}
          style={{ fontSize: "0.9em" }}
          linkClassName="dynobase-download-button"
          buttonClassName="dynobase-download-button-btn"
        >
          <StaticImage
            backgroundColor="transparent"
            src={"../images/download.png"}
            alt="download dynobase icon"
            style={{ marginRight: 10, marginBottom: 1 }}
          />
          Download Now
        </Button>
      </Box>
      <Box width={[1, 1, 1 / 2]}>
        <StaticImage
          src={"../images/codegen-transparent.png"}
          alt="Dynobase Codegen Feature"
        />
      </Box>
    </Flex>
  </Flex>
)

export default CTA
