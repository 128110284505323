import React from "react"
import { Flex, Box } from "rebass"
import Button from "@components/button"
import { download } from "@constants/urls.js"
import MobileDownloadCTA from "@components/MobileDownloadCTA.js"
import { StaticImage } from "gatsby-plugin-image"

const options = [
  {
    title: "Dynobase is a Professional GUI Client for DynamoDB",
    subtitle: "Start your 7-day free trial today",
  },
  {
    title: "Spend less time in the AWS console, use Dynobase.",
    subtitle: "First 7 days are on us. No strings attached.",
  },
  {
    title: "Login to the AWS Console less. Use Dynobase.",
    subtitle: "Try 7-day free trial. No credit card needed.",
  },
  {
    title: "Tired of switching accounts and regions? Use Dynobase.",
    subtitle: "Try 7-day free trial. No credit card needed.",
  },
  {
    title: "Better DynamoDB experience.",
    subtitle: "Try 7-day free trial. No strings attached.",
  },
  {
    title: "Tired of AWS Console? Try Dynobase.",
    subtitle: "First 7 days are. No credit card needed.",
  },
]

const CTA = () => (
  <Flex
    mt={60}
    pt={22}
    px={22}
    pb={28}
    flexWrap="wrap"
    style={{
      border: "0px solid rgb(213, 213, 213)",
      boxShadow: "rgba(0, 0, 0, 0.15) 3px 4px 10px 4px",
      borderRadius: 7,
    }}
  >
    <Box width={[1, 1, 3 / 4]} mb={[10, 10, 0]}>
      <h3
        style={{
          marginBottom: "5px",
          lineHeight: "1.5",
          letterSpacing: "-0.3px",
        }}
      >
        {options[Math.floor(Math.random() * options.length)].title}
      </h3>
      <h3 style={{ color: "#aaa", marginBottom: 0, letterSpacing: "-0.3px" }}>
        {options[Math.floor(Math.random() * options.length)].subtitle}
      </h3>
    </Box>
    <MobileDownloadCTA />
    <Box
      width={[1, 1, 1 / 4]}
      display={["none", "none", "flex"]}
      justifyContent="center"
      alignItems="center"
      margin="auto"
    >
      <Button
        primary
        href={download}
        style={{ fontSize: "0.9em" }}
        linkClassName="dynobase-download-button"
        buttonClassName="dynobase-download-button-btn"
      >
        <StaticImage
          src={"../images/download.png"}
          alt="download dynobase icon"
          style={{ marginRight: 10, marginBottom: 1 }}
        />
        Download Now
      </Button>
    </Box>
  </Flex>
)

export default CTA
