import React from "react"
import { Link } from "gatsby"

const Button = ({ children, href, to, primary, style, onClick, linkClassName, buttonClassName }) => (
  <button
    onClick={onClick}
    className={buttonClassName}
    style={{
      backgroundColor: primary ? "rgb(24, 144, 255)" : "white",
      color: primary ? "white" : "rgb(24, 144, 255)",
      border: "none",
      padding: "10px 20px",
      borderRadius: 5,
      fontWeight: 600,
      boxShadow:
        "0 2px 4px 0 rgba(136, 144, 195, 0.2), 0 5px 15px 0 rgba(37, 44, 97, 0.35)",
      ...style,
    }}
  >
    {href && (
      <a
        style={{
          textDecoration: "none",
          color: primary ? "white" : "rgb(24, 144, 255)",
          alignItems: "center",
          display: "flex",
        }}
        rel="noopener"
        href={href}
        className={linkClassName}
      >
        {children}
      </a>
    )}
    {to && <Link to={to}>{children}</Link>}
    {!to && !href && children}
  </button>
)

export default Button
