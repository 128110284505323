import React from "react"
import Helmet from "react-helmet"

const ArticleJsonLD = ({ headline, image, datePublished }) => {
  const ldJson = {
    "@context": "https://schema.org",
    "@type": "TechArticle",
    headline: headline.substring(0, 110),
    image: [image],
    datePublished,
    author: {
      "@type": "Person",
      name: "Rafal Wilinski",
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://google.com/article",
    },
    keywords: "dynamodb serverless aws nosql",
    publisher: {
      "@type": "Organization",
      name: "Dynobase",
      logo: {
        "@type": "ImageObject",
        url:
          "https://pbs.twimg.com/profile_images/1217768588624506880/YwZeRuPe_400x400.jpg",
      },
    },
  }

  const ldJsonString = JSON.stringify(ldJson, null, 2)

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{ldJsonString}</script>
      </Helmet>
    </>
  )
}

export default ArticleJsonLD
