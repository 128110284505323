import React from "react"
import Helmet from "react-helmet"

const FAQJsonLD = ({ questions }) => {
  const mainEntity = questions.map(question => ({
    "@type": "Question",
    name: question.question,
    acceptedAnswer: {
      "@type": "Answer",
      text: question.answer,
    },
  }))

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity,
  }

  const ldJsonString = JSON.stringify(ldJson, null, 2)

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{ldJsonString}</script>
      </Helmet>
    </>
  )
}

export default FAQJsonLD
