import React from "react"
import { Box, Flex } from "rebass"
import Button from "@components/button"
import Modal from "react-modal"

const customStyles = {
  overlay: {
    backgroundColor: "rgba(76, 76, 76, 0.88)",
  },
  content: {
    top: "15vh",
    bottom: null,
    maxWidth: '95vw', // restricts the maximum width to 95% of the viewport width
    left: '2.5vw', // center the modal horizontally by offsetting the left position by half of the remaining viewport width
    right: '2.5vw', // do the same for the right
    position: 'absolute', // needed to allow left/right/top/bottom to work
  },
}

const MobileDownloadCTA = props => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <Box maxWidth={"80vw"}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <Flex flexDirection="column">
          <span
            onClick={closeModal}
            style={{ alignSelf: "flex-end", cursor: "pointer" }}
          >
            x
          </span>
          <h2 style={{ textAlign: "center", lineHeight: "1.5", paddingTop: "8px"}}>Download Only Available On Desktop</h2>
          <div>
            Dynobase is available for Mac, Windows & Linux. Please switch to your desktop machine to download the 7-day free trial.
          </div>
          <Button
            primary={true}
            style={{
              display: "table",
              margin: "20px 10px auto",
              outline: "none",
              cursor: "pointer",
              borderRadius: 0,
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <span><span style={{paddingRight: "6px"}}>👍</span> Cool, Will Do</span>
          </Button>
        </Flex>
      </Modal>
      </Box>
      <Box display={["block", "block", "none"]}>
        <Button
          primary
          onClick={openModal}
          style={{
            display: "flex",
            margin: "20px auto 10px",
            cursor: "pointer",
            outline: "none",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            color: "rgb(24, 144, 255)",
          }}
          linkClassName="dynobase-download-button"
          buttonClassName="dynobase-download-button-btn"
        >
          <span>Download Now</span>
        </Button>
      </Box>
    </React.Fragment>
  )
}

export default MobileDownloadCTA
