exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aws-dynamodb-console-alternative-js": () => import("./../../../src/pages/aws-dynamodb-console-alternative.js" /* webpackChunkName: "component---src-pages-aws-dynamodb-console-alternative-js" */),
  "component---src-pages-buy-js": () => import("./../../../src/pages/buy.js" /* webpackChunkName: "component---src-pages-buy-js" */),
  "component---src-pages-dynamodb-capacity-and-item-size-calculator-js": () => import("./../../../src/pages/dynamodb-capacity-and-item-size-calculator.js" /* webpackChunkName: "component---src-pages-dynamodb-capacity-and-item-size-calculator-js" */),
  "component---src-pages-dynamodb-capacity-modes-js": () => import("./../../../src/pages/dynamodb-capacity-modes.js" /* webpackChunkName: "component---src-pages-dynamodb-capacity-modes-js" */),
  "component---src-pages-dynamodb-errors-js": () => import("./../../../src/pages/dynamodb-errors.js" /* webpackChunkName: "component---src-pages-dynamodb-errors-js" */),
  "component---src-pages-dynamodb-faq-js": () => import("./../../../src/pages/dynamodb-faq.js" /* webpackChunkName: "component---src-pages-dynamodb-faq-js" */),
  "component---src-pages-dynamodb-json-converter-tool-js": () => import("./../../../src/pages/dynamodb-json-converter-tool.js" /* webpackChunkName: "component---src-pages-dynamodb-json-converter-tool-js" */),
  "component---src-pages-dynamodb-pricing-calculator-js": () => import("./../../../src/pages/dynamodb-pricing-calculator.js" /* webpackChunkName: "component---src-pages-dynamodb-pricing-calculator-js" */),
  "component---src-pages-dynamodb-query-builder-js": () => import("./../../../src/pages/dynamodb-query-builder.js" /* webpackChunkName: "component---src-pages-dynamodb-query-builder-js" */),
  "component---src-pages-dynamodb-query-examples-js": () => import("./../../../src/pages/dynamodb-query-examples.js" /* webpackChunkName: "component---src-pages-dynamodb-query-examples-js" */),
  "component---src-pages-dynamodb-single-table-design-tool-js": () => import("./../../../src/pages/dynamodb-single-table-design-tool.js" /* webpackChunkName: "component---src-pages-dynamodb-single-table-design-tool-js" */),
  "component---src-pages-dynamodb-table-schema-design-tool-js": () => import("./../../../src/pages/dynamodb-table-schema-design-tool.js" /* webpackChunkName: "component---src-pages-dynamodb-table-schema-design-tool-js" */),
  "component---src-pages-dynamodb-tools-js": () => import("./../../../src/pages/dynamodb-tools.js" /* webpackChunkName: "component---src-pages-dynamodb-tools-js" */),
  "component---src-pages-dynamodb-tutorials-js": () => import("./../../../src/pages/dynamodb-tutorials.js" /* webpackChunkName: "component---src-pages-dynamodb-tutorials-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-keyboard-shortcuts-js": () => import("./../../../src/pages/keyboard-shortcuts.js" /* webpackChunkName: "component---src-pages-keyboard-shortcuts-js" */),
  "component---src-pages-nosql-workbench-alternative-js": () => import("./../../../src/pages/nosql-workbench-alternative.js" /* webpackChunkName: "component---src-pages-nosql-workbench-alternative-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-changelog-js": () => import("./../../../src/templates/changelog.js" /* webpackChunkName: "component---src-templates-changelog-js" */),
  "component---src-templates-code-examples-js": () => import("./../../../src/templates/codeExamples.js" /* webpackChunkName: "component---src-templates-code-examples-js" */),
  "component---src-templates-error-page-js": () => import("./../../../src/templates/errorPage.js" /* webpackChunkName: "component---src-templates-error-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../../../src/templates/faqPage.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-mdx-content-page-js": () => import("./../../../src/templates/mdxContentPage.js" /* webpackChunkName: "component---src-templates-mdx-content-page-js" */),
  "component---src-templates-product-feature-js": () => import("./../../../src/templates/productFeature.js" /* webpackChunkName: "component---src-templates-product-feature-js" */),
  "component---src-templates-single-table-examples-js": () => import("./../../../src/templates/single-table-examples.js" /* webpackChunkName: "component---src-templates-single-table-examples-js" */),
  "component---src-templates-single-table-model-js": () => import("./../../../src/templates/single-table-model.js" /* webpackChunkName: "component---src-templates-single-table-model-js" */)
}

